import React from "react";
import '../styles/App.css';
import hero from '../triocast_hero.jpeg'
import { useTrackingCode } from "react-hubspot-tracking-code-hook";

function Home() {

  const { setContentType, setPathPageView } = useTrackingCode();

  setContentType('landing-page')
  setPathPageView('/home')

  return (
    <div className="App">
      <header className="App-header">
        <img src={hero} className="App-logo" alt="logo" />
        <p><strong>Triocast</strong></p>
        <p>Exceptional metal casting for the jewellery industry</p>
        <br/>
      </header>
    </div>
  );
}

export default Home;
