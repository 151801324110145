import React from "react";
import '../styles/App.css';
import { useTrackingCode } from "react-hubspot-tracking-code-hook";

function Assurance() {
  const { setContentType, setPathPageView } = useTrackingCode();

  setContentType('standard-page')
  setPathPageView('/assurance')

  return (
    <div className="App-content">
      <header className="App-header">
        <div className="container">
          <h1 className="font-weight-light">Assurance</h1>
          <h2>
          Guarantee
          </h2>
          <p>
          Our quality control standards begin when our clients mold, model, wax, or resin enters our doors.  Every stage of our process is examined thoroughly with each completed casting receiving a rigorous inspection.  At Triocast, we return our clients' work only if it has met our quality control standards.
          </p>
          <h2>
          Confidentiality
          </h2>
          <p>
          The protection of design, molds and models submitted to Triocast is of the utmost importance.  In order to secure the confidentiality of our clients intellectual property, it is our policy that upon the receipt of payment, any digital files submitted to Triocast will be permanently and securely deleted.  At Triocast we recognize that designs, models, and molds are the singular property of the client.
          </p>
        </div>
      </header>
    </div>
  );
}

export default Assurance;
