import React from "react";
import '../styles/App.css';
import { useTrackingCode } from "react-hubspot-tracking-code-hook";

function Services() {
  const { setContentType, setPathPageView } = useTrackingCode();

  setContentType('standard-page')
  setPathPageView('/services')

  return (
    <div className="App-content">
      <header className="App-header">
        <div className="container">
          <h1 className="font-weight-light">Services</h1>

          <h2>Precious Metal Casting</h2>
          <p>Our precious metal casting services are performed exclusively in-house which allows us to provide our customers with unparalleled results.  At Triocast, we are able to accommodate your needs whether it be an intricately designed custom piece or a high volume order.  Accepting both resin and wax models for casting, turn around times vary by material.  Wax models can be expected to be completed in 24 hours, whereas resin models have a lead time of 48 hours.  In both cases, patterns must be submitted before noon.  We offer our services in any karat or colour of gold, sterling silver, and specializing in platinum casting.  We utilize ruthenium in our platinum casting providing a superior product, Platinum 950, to our customers.  Similarly to resin casting, our platinum casting services can also expect to have a turn around time of 48 hours.  It is our mission to meet the ever evolving demands of our clients.</p>

          <h2>Mold Making</h2>
          <p>We are able to provide our clients with in-house mold making services in vulcanized rubber and cold-form silicone.  Each method is offered with a 24-hour lead time.  Should your project involve large scale production, a CNC metal mold may be the best solution.  Triocast is able to provide its clients this service through a trusted third-party contractor.  When subcontracting is required for a project we receive, Triocast  works exclusively with companies that share and hold themselves to the same confidentiality standards we provide to our clients.</p>
        </div>
      </header>
    </div>
  );
}

export default Services;
