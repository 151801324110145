import React from "react";
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';
import '../styles/Contact.css';
import '../styles/App.css';
import { Button, Form } from "react-bootstrap";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";

function Contact() {
  const { setContentType, setPathPageView } = useTrackingCode();

  setContentType('standard-page')
  setPathPageView('/contact')

  const SERVICE_ID = "website_contact_form";
  const TEMPLATE_ID = "website_contact_form";
  const USER_ID = "user_8i1XPsUpvGU47zSTOS2rp";

  const initialFormData = Object.freeze({
    name: "",
    email: "",
    message: ""
  });

  const [formData, updateFormData] = React.useState(initialFormData);

  const handleChange = (e) => {
    updateFormData({
      ...formData,

      [e.target.name]: e.target.value.trim()
    });
  };

  const isFormValid = () => {
    console.log(formData, "formData");
    return !(!formData.name || !formData.email || !formData.message);
  }

  const handleOnSubmit = (event) => {
    event.preventDefault();
    if(!isFormValid()) {
      promptInvalid();
    } else {
      submitForm(event);
    }
  }

  const promptInvalid = () => {
    Swal.fire({
      icon: 'error',
      title: 'Please complete all fields'
    });
  }

  const submitForm = (event) => {
    const dataToSubmit = { user_name: formData.name, user_email: formData.email, user_message: formData.message };
    emailjs.send(SERVICE_ID, TEMPLATE_ID, dataToSubmit, USER_ID)
      .then(
        (result) => handleSuccess(event, result),
        (error) => handleError(error)
      );
  }

  const handleSuccess = (event, result) => {
    console.log(result.text);
    Swal.fire({
      icon: 'success',
      title: 'Message sent!'
    });
    updateFormData({
      name: "",
      email: "",
      message: ""
    });
    event.target.reset()
  }

  const handleError = (error) => {
    console.log(error.text);
    Swal.fire({
      icon: 'error',
      title: 'Something went wrong with our email sending provider',
      text: error.text,
    })
  }

  return (
    <div className="App-content">
      <header className="App-header">
        <div className="container">
        <h1 className="font-weight-light">Contact</h1>
          <h3>Send us a message</h3>
          <Form onSubmit={handleOnSubmit}>
            <Form.Group id="name" controlId='formBasicText' name="first_name">
              <Form.Label>Name</Form.Label>
              <Form.Control type="name" placeholder="Enter name" onChange= {handleChange} name="name" />
            </Form.Group>
            <Form.Group controlId='formBasicEmail'>
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder="Enter email" onChange= {handleChange} name="email" />
            </Form.Group>
            <Form.Group controlId='formBasicText'>
              <Form.Label>Message</Form.Label>
              <Form.Control as="textarea" rows={3} placeholder="Enter a message" onChange= {handleChange} name="message" />
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>

          <h3>Visit us</h3>
          <p className="Contact-text">
            107 Church Street<br />
            Unit 200<br />
            Toronto, ON<br />
            M5C 2G5<br />
          </p>

          <h3>Call us</h3>
          <p className="Contact-text">
            <a href="tel:+1 416 456 3666">+1 416 456 3666</a><br />
            <a href="tel:+1 844 456 3666">+1 844 456 3666</a>
          </p>

        </div>
      </header>
    </div>
  );
}

export default Contact;
