import React from "react";
import '../styles/App.css';
import { useTrackingCode } from "react-hubspot-tracking-code-hook";

function About() {
  const { setContentType, setPathPageView } = useTrackingCode();

  setContentType('standard-page')
  setPathPageView('/about')


  return (
    <div className="App-content">
      <header className="App-header">
        <div className="container">
          <h1 className="font-weight-light">About</h1>
          <p>
          Triocast was founded with exceptional quality as its cornerstone principle.  Established in 2021, our team has combined their knowledge and expertise to offer our clients over 80 years of combined industry experience.  Attention to detail is practised at every stage of our process.  Upon receiving work from you, we evaluate which of our casting methods will be best-suited to fulfill your needs.  We then thoroughly examine the model to ensure it has been properly sprued and placed accordingly on the tree for optimal metal flow. Depending on the demands of the model and material to be cast, we choose from one of our five varieties of investment in order to yield the most precise replication of design, from model to metal.  Metal castings are then carefully cut from the tree, with close attention paid to ensure that we do not damage or warp the design.  When removing the gate, we are mindful of how much sprue remains on the final piece. This ensures that our clients are only paying for the amount of metal their design demands.  Once the metal models are freed of the gates, the pieces are rigorously inspected to ensure our clients receive a final product which meets our quality-control standards.  Only after completing these final stages of post-cast inspection does the piece move into the administrative process.
          </p>
        </div>
      </header>
    </div>
  );
}

export default About;
