import React from "react";
import '../styles/App.css';
import { useTrackingCode } from "react-hubspot-tracking-code-hook";

function Antispam() {
  const { setContentType, setPathPageView } = useTrackingCode();

  setContentType('standard-page')
  setPathPageView('/assurance')

  return (
    <div className="App-content">
      <header className="App-header">
        <div className="container">
          <h1 className="font-weight-light">Anti-Spam Policy</h1>
          <p>
            At Triocast, we consider data privacy to be of paramount importance. This is reflected in our <a href="/assurance">assurances</a>, and similarly in our handling of <a href="https://fightspam.gc.ca/">Canada’s Anti-Spam Legislation</a> (CASL).
          </p>
          <h2>Immediate actions</h2>
          <ul>
            <li><strong>I would like to keep up-to-date with Triocast's services</strong> - We're happy to hear that! Please <a href="/">subscribe to our mailing list</a> for future updates.</li>
            <li><strong>I don't want to receive further emails</strong> - We won't send you any emails beyond the first without your explicit consent.</li>
          </ul>

          <h2>Why did I receive an email?</h2>
          <p>
            If you have received an outreach email from us, it is because we believe two things to be true:
          </p>
          <ol>
            <li>Your email address is published in a public place (either your own website, or that of the Canadian Jewellery Group <a href="https://canadianjewellerygroup.ca/cjg-retail-members-directory">Retail Member's Directory</a>)</li>
            <li>We believe that the services that we offer are relevant to the interests of your business</li>
          </ol>
          <p>
            Providing that both of these criteria are true, we believe that we are acting in accordance with CASL's definition of <a href="https://laws-lois.justice.gc.ca/eng/acts/E-1.6/page-1.html">implied consent</a> found in section 10(9)(b), namely that:
          </p>
          <p>
            <em>
              Consent is implied for the purpose of section 6 only if ... the person to whom the message is sent has conspicuously published, or has caused to be conspicuously published, the electronic address to which the message is sent, the publication is not accompanied by a statement that the person does not wish to receive unsolicited commercial electronic messages at the electronic address and the message is relevant to the person’s business, role, functions or duties in a business or official capacity.
            </em>
          </p>
        </div>
      </header>
    </div>
  );
}

export default Antispam;
