import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  About,
  Antispam,
  Assurance,
  Contact,
  Footer,
  Home,
  Navigation,
  Process,
  Services
} from "./components";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Navigation />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/process" element={<Process />} />
          <Route path="/services" element={<Services />} />
          <Route path="/assurance" element={<Assurance />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/antispam" element={<Antispam />} />
        </Routes>
      <Footer />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
