import React from "react";
import '../styles/App.css';
import { useTrackingCode } from "react-hubspot-tracking-code-hook";

function Process() {
  const { setContentType, setPathPageView } = useTrackingCode();

  setContentType('standard-page')
  setPathPageView('/process')

  return (
    <div className="App-content">
      <header className="App-header">
        <div className="container">
          <h1 className="font-weight-light">Process</h1>
            <p>
            Lost wax metal casting is the process by which a wax, resin, or other combustible material model is converted into its exact metal copy.
            </p>
            <p>
            Our methods are described as follows:
            </p>
            <ul>
              <li>
                <p>
                <b>Induction casting</b> - a method which utilizes an electro-magnetic field for the melting of metal.  Upon melting, the metal is then injected with the assistance of pressurized argon gas into the negative space of the casting flask.
              </p>
            </li>
            <li>
              <p>
              <b>Vacuum casting</b> - a process which utilizes a vacuum.  This vacuum pulls the air and gases from the mold.  With the assistance of gravitational pull, this allows the molten melt to flow quickly throughout the negative space of the flask.
              </p>
            </li>
            <li>
              <p>
              <b>Centrifugal casting</b> - a casting technique that utilizes centrifcal forces to inject the molten metal into the negative space of a casting flask.
              </p>
            </li>
          </ul>
        </div>
      </header>
    </div>
  );
}

export default Process;
